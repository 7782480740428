@import '../../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  & :global(.NamedLink_active), & .currentPage {
    color: var(--colorBlack);
  
    /* black left border */
    border-left: 5px solid black;
    margin-left: -24px;
    padding-left: 19px;
  }
  & .orderLink{
    color: var(--marketplaceColor);
  
    border-left: unset;
    margin-left: unset;
    padding-left: unset;
  }
 & .activeLink{
  color: var(--colorBlack);
  
  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
 }
}
.arabicDirection{
  direction: rtl;
  & .activeLink, & :global(.NamedLink_active), & .currentPage{
    color: var(--colorBlack);
    
    border-right: 5px solid black;
    margin-right: -24px;
    padding-right: 19px !important;
    border-left: unset;
    margin-left: unset;
    padding-right: unset;
   }
}
.customerLanguage {
  gap: 5px;
  display: flex;
  margin-bottom: 16px;
}
.languageText {
  cursor: pointer;
  color: #6C7275;
  font-family: Actor;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
}
.activeLanguage{
  text-decoration: underline;
}
.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.notificationLink{
  margin-left: 24px;
}
.accountLinksWrapper,
.customLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.accountLinksWrapper {
  margin-bottom: 100px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 36px 0 0 0;
  & svg{
    fill: transparent;
    width: 80%;
    height: 80%;
  }
}

.greeting {
  /* Font */
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  padding: 3px 0 3px 0;
  margin-bottom: 1px;
  margin-top: 16px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin: 0 0 36px 0;
  }
}

.customLinkFont {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 1px 0 7px 0;
  }
}

.inbox {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 36px;
  margin-top: 36px;
  line-height: 34px;
  font-size: 24px;
}
.authenticationLinks {
  white-space: nowrap;
  & :global(.NamedLink_active){
    color: var(--marketplaceColor) !important;
    border-left: unset !important;
    margin-left: unset !important;
    padding-left: unset !important;
  }
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
  border-right: none !important;
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
  border-right: none !important;
}



.spacer {
  width: 100%;
  height: 124px;
}

.verifyTitle {
    color: #424242;
  font-family: 'Actor';
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0px;
  margin-bottom: 0;
}
.verifySubTitle {
    color: #666666;
    font-family: 'Actor';
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0px;
    margin: 0 0 30px;
    padding: 0;
}
.itemCheckbox{
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 16px;
    color: #666666;
    font-family: 'Actor';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    cursor: pointer;
    & svg{
        height: 21px;
        width: 21px;
    }
}
.buttonSubmit{
    margin-top: 60px;
    border-radius: 100px;
    font-family: Inter;
font-weight: 700;
font-size: 16px;
line-height: 26px;
letter-spacing: 2%;
&:hover{
    opacity: 0.9;
}
}
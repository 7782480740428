@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  /* width: 100%;
  height: var(--topbarHeightDesktop); */

  /* Layout for child components */
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0; */

  /* fill */
  /* background-color: var(--colorWhite); */

  /* shadows */
  /* box-shadow: var(--boxShadowLight); */

  /* @media (--viewportLarge) {
    padding: 0 24px 0 0;
  } */
}
.arabicDirection{
  direction: rtl;
  & .menuItemBorder{
    left: unset !important;
    right: 0;
  }
  & .logoWrapper{
    margin-right: 0 !important;
    margin-left: 93px;
  }
  & .logoIcon{
    margin-right: 0 !important;
    margin-left: 78px;
  }
  & .logoutButton, & .menuLink{
    text-align: right !important;
  }
}
/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
}

.logoLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
}

/* Search */
.searchLink {
  min-width: 320px;
  height: 100%;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.takeAvailableSpace {
  flex-grow: 1;

  /* At 1024px, the translations might need more space than what searchLink otherwise gets. */
  min-width: 220px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  padding-right: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* These is used with Inbox, Sign up, and Log in */
.topbarLink {
  flex-shrink: 0;

  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
  position: relative;

  &:hover {
    text-decoration: none;
  }
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.logoImage{
  width: 142px;
  height: 100px;
  object-fit: contain;
}
.currentPage, .activeLink {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.headerWrapper {
  height: 68px;
  display: flex;
  max-width: 1024px;
  padding: 12px 16px;
  margin: 32px auto 0;
  border-radius: 24px;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px #0000000D;

  & .leftBox {
    display: flex;
    align-items: center;

    & .logoIcon {
      margin-right: 78px;
      & svg{
        width: 60px;
        height: 60px;
      }

      & a {
        padding: 0;

        &:hover {
          border-bottom: 0;
          text-decoration: none;
        }
      }
    }
  }

  & .centerHeader {
    gap: 32px;
    display: flex;

    & .link {
      font-size: 16px;
      cursor: pointer;
      color: #6B7280;
      font-weight: 500;
      line-height: 28px;
      transition-duration: 0.5s;

      &:hover {
        text-decoration: none;
        color: #000;
      }
    }
  }

  & .rightBarHeading {
    gap: 12px;
    display: flex;
    align-items: center;

    & .startFreeButton {
      height: 44px;
      display: block;
      font-size: 16px;
      color: #1E1E1E;
      font-weight: 400;
      line-height: 28px;
      padding: 8px 24px;
      text-align: center;
      border-radius: 12px;
      background-color: #FFD046;
      border: 1px solid #FFD046;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.signinButton {
  height: 44px;
  display: block;
  font-size: 16px;
  color: #6B7280;
  font-weight: 400;
  line-height: 28px;
  padding: 8px 24px;
  text-align: center;
  border-radius: 12px;
  background-color: #fff;
  transition-duration: 0.5s;
  border: 1px solid #D1D5DB;

  &:hover {
    color: #000;
    text-decoration: none;
    border: 1px solid #000;
  }

  & .signupLabel {}
}
.afterLoginWrapper {
  display: flex;
  background-color: #fff;

  & .loginWrapper {
    width: 100%;
    margin: 0 auto;
    padding: 12px 0;
    max-width: calc(1228px + 48px);
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .headerLeft {
      display: flex;
      align-items: center;

      & .logoWrapper {
        margin-right: 93px;
          & svg{
            width: 80px;
            height: 80px;
          }
        & .logoLink {
          padding: 0;

          &:hover {
            border-bottom: 0;
          }

          &>div {
            margin: 0;
          }
        }
      }

      & .menuList {
        & .menuNames {
          gap: 32px;
          display: flex;
          & :global(.NamedLink_active){
            position: relative;
            font-weight: 800 !important;
            &:after{
              position: absolute;
              content: "";
              width: 100%;
              height: 7px;
              border-radius: 20px;
              background-color: #ECBC30;
              bottom: -7px;
              right: 0;
            }
          }
          & .link {
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: #6B7280;
            border-radius: 0;
            transition-duration: 0.5s;
            border-bottom: 7px solid #ecbd3000;

            &:hover {
              color: #000;
              /* font-weight: 800; */
              text-decoration: none;
              position: relative;
              &:after{
                position: absolute;
                content: "";
                width: 100%;
                height: 7px;
                border-radius: 20px;
                background-color: #ECBC30;
                bottom: -7px;
                right: 0;
              }
            }
          }
        }
      }
    }

    & .headerRight {
      gap: 18px;
      display: flex;
      align-items: center;

      & .searchInput {
        & .topbarSearch {
          &>div {
            border: 1px solid #DCDFE3;
            border-radius: 8px !important;
          }

          & div {
            padding: 0 5px;
            height: 36px;
            border-radius: 0;
            background-color: transparent;

            & svg {
              transform: scaleX(-1);

              & g {
                stroke: #8F95A3;
              }
            }

            & input {
              height: 36px;
              border-radius: 0;
              background-color: transparent;
            }
          }
        }
      }

      & .settingIcon {}

      & .profileIcon {
        &>div {
          &>button {
            &:hover {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
.profileMenuLabel {
  padding: 0;

  &>div {
    width: 24px;
    height: 24px;
    background-image: unset;
  }

  &:hover {
    border-bottom: 0;
  }
}
.wishlistPage{
  margin-right: -3px;
  & svg{
    fill: transparent;
  }
}
.customerHeader {
  background-color: #fff;

  & .customerWrapper {
    width: calc(100% - 12px);
    display: flex;
    margin: 0 auto;
    padding: 16px 24px;
    max-width: calc(1360px + 48px);
    align-items: center;
    justify-content: space-between;

    & .customerLeftBox {
      gap: 40px;
      display: flex;
      align-items: center;
      flex: 2.3;
       @media (max-width: 1300px) {
        gap: 32px;
       }
       @media (max-width: 1360px) {
        flex: 3;
       }
       & :global(.NamedLink_active){
        color: #141718 !important;
        font-weight: 600 !important;
       }
      & .link {
        font-size: 14px;
        font-weight: 500;
        color: #6C7275;
        line-height: 24px;

        &:hover {
          color: #141718;
          text-decoration: none;
        }
      }
    }

    & .customerCenterBox {
      /* flex: 1; */
      text-align: center;
      & svg{
        width: 80px;
        height: 80px;
      }
    }

    & .customerRightBox {
      flex: 2;
      gap: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & .userProfile {
        & .profileMenuContent{
          padding-bottom: 20px;
        }
      }

      & .customerLanguage {
        gap: 5px;
        display: flex;

      }

      & .cartLogo {
        display: flex;
        align-items: center;

        & .cartCount {
          width: 20px;
          height: 20px;
          color: #fff;
          display: flex;
          font-size: 12px;
          font-weight: 700;
          margin-left: 5px;
          line-height: 10px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: #141718;
        }
      }
    }
  }
}
.languageText {
  cursor: pointer;
  color: #6C7275;
  font-family: Actor;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
}
.activeLanguage{
  text-decoration: underline;
}
.hidden{
  display: none;
}